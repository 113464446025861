<template>
  <div id="app">
    <div class="loader" v-if="!view"></div>

    <router-view v-if="view" />
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  data() {
    return {
      view: false,
    };
  },
  created() {
    setTimeout(() => {
      this.view = true;
    }, 1000);
  },
};
</script>
<style>
.loader {
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #3498db; /* Blue border on top */
  border-radius: 50%; /* Make it circular */
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Spin animation */
  margin-top: 25%;
  margin-inline-start: 48%;
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown){
  padding: 0!important;
}
.multiselect__placeholder{
  display: block!important;
  text-align: start!important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<!-- 
<style scoped>
.center {
  text-align: center;
}

.center img {
  display: block;
  margin: 0 auto;
  max-width: 50%;
  max-height: 100%;
}
</style> -->
