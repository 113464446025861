import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/pages/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,

      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom });
      },
    },
  },
  {
    path: "/",
    name: "home",
    meta: {
      priv: "dashboard",
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/client/all",
    name: "Allclient",

    meta: {
      priv: "client",
      authRequired: true,
    },
    component: () => import("../views/pages/developmentDone/clients/index"),
  },
  {
    path: "/client/:id",
    name: "clientPage",

    meta: {
      priv: "client",
      authRequired: true,
    },
    component: () =>
      import("../views/pages/developmentDone/clients/clientPage"),
  },
  {
    path: "/agent/all",
    name: "Allagent",

    meta: {
      priv: "agent",
      authRequired: true,
    },
    component: () => import("../views/pages/developmentDone/agents/index"),
  },
  {
    path: "/agent/:id",
    name: "agentPage",

    meta: {
      priv: "agent",
      authRequired: true,
    },
    component: () => import("../views/pages/developmentDone/agents/agentPage"),
  },
  {
    path: "/consultant/all",
    name: "Allconsultant",

    meta: {
      priv: "consultant",
      authRequired: true,
    },
    component: () => import("../views/pages/developmentDone/consultants/all"),
  },
  {
    path: "/consultant/calendar",
    name: "calendarConsult",

    meta: {
      priv: "consultant",
      authRequired: true,
    },
    component: () =>
      import("../views/pages/developmentDone/consultants/calendar"),
  },
  {
    path: "/consultant/:id",
    name: "consultantPage",

    meta: {
      priv: "consultant",
      authRequired: true,
    },
    component: () =>
      import("../views/pages/developmentDone/consultants/consultantPage"),
  },
  {
    path: "/question/all",
    name: "Allquestion",

    meta: {
      priv: "question",
      authRequired: true,
    },
    component: () => import("../views/pages/developmentDone/questions/all"),
  },
  {
    path: "/question/notAnswerd",
    name: "notAnsQuestion",

    meta: {
      priv: "question",
      authRequired: true,
    },
    component: () =>
      import("../views/pages/developmentDone/questions/notAnswerd"),
  },
  {
    path: "/question/:id",
    name: "questionPage",

    meta: {
      priv: "question",
      authRequired: true,
    },
    component: () =>
      import("../views/pages/developmentDone/questions/questionPage"),
  },
  {
    path: "/contract/all",
    name: "Allcontract",

    meta: {
      priv: "contract",
      authRequired: true,
    },
    component: () => import("../views/pages/developmentDone/contracts/all"),
  },
  {
    path: "/contract/bending",
    name: "bendingContracts",

    meta: {
      priv: "contract",
      authRequired: true,
    },
    component: () => import("../views/pages/developmentDone/contracts/pending"),
  },
  {
    path: "/contract/:id",
    name: "contractPage",

    meta: {
      priv: "contract",
      authRequired: true,
    },
    component: () =>
      import("../views/pages/developmentDone/contracts/contractPage"),
  },
  {
    path: "/payments",
    name: "Allpayment",

    meta: {
      priv: "payment",
      authRequired: true,
    },
    component: () => import("../views/pages/developmentDone/payments/index"),
  },
  {
    path: "/profile/:id",
    name: "profile",

    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/developmentDone/profile/index"),
  },
  {
    path: "/contract-types",
    name: "Allcontract_type",

    meta: {
      priv: "contract_type",
      authRequired: true,
    },
    component: () =>
      import("../views/pages/developmentDone/contractTypes/index"),
  },
  {
    path: "/consultant-prices",
    name: "Allconsultant_price",

    meta: {
      priv: "contract_type",
      authRequired: true,
    },
    component: () =>
      import("../views/pages/developmentDone/consultantPrices/index"),
  },
  {
    path: "/test",
    name: "test",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/developmentTests/test.vue"),
  },
  {
    path: "/icons",
    name: "icons",
    component: () => import("../views/pages/icons/font-awesome/index"),
  },
  {
    path: "/pages/notAuth",
    name: "notAuth",
    component: () => import("../views/pages/notAuth"),
  },

  {
    path: "*",
    component: () => import("../views/pages/error-404"),
  },
];
